import { useState, useEffect } from 'react';

export const useStickyBoolean = (initialValue: any) => {
  const [wasEverTrue, setWasEverTrue] = useState(initialValue);

  useEffect(() => {
    if (initialValue) {
      setWasEverTrue(true);
    }
  }, [initialValue]);

  return wasEverTrue;
};
