// Styles
import { StyledMutationAlert, StyledMutationAlertProps } from './styles';

// Components
import { Alert as UIAlert } from '../Alert';

// Hooks
import { useTranslations } from 'next-intl';

// Types
export interface MutationAlertProps extends StyledMutationAlertProps {}

// Component
export const MutationAlert = ({
  AlertComponent = UIAlert,
  ...props
}: MutationAlertProps) => {
  const t = useTranslations('UI.MUTATION_ALERT');
  return <StyledMutationAlert loadingMessage={t('LOADING')} defaultErrorMessage={t('ERROR')} {...{
    AlertComponent
  }} {...props} />;
};