import React from 'react';

// Styles
import s from './styles.module.css';

// Components
import { Close as RadixClose, DialogCloseProps as RadixCloseProps } from '@radix-ui/react-dialog';
import { CloseIcon, CloseIconSharedProps } from '../CloseIcon';

// Re export components
export { RadixClose };

// Re export types
export type { RadixCloseProps };

// Types
export interface CloseSharedProps extends CloseIconSharedProps {
  CloseComponent?: React.FC<CloseProps> | React.FC<any>;
  closeClass?: string;
}
export interface CloseSpecificProps extends CloseSharedProps {
  clsPrefix?: string;
}
export interface CloseProps extends CloseSpecificProps, RadixCloseProps {}
export const Close = ({
  CloseComponent = RadixClose,
  CloseIconComponent = CloseIcon,
  closeIconClass,
  clsPrefix = 'radix-dialog',
  closeClass = '',
  className = closeClass,
  children,
  disabled,
  ...props
}: CloseProps) => {
  return <CloseComponent className={`${s.close} ${clsPrefix}-close ${className}`} {...{
    disabled
  }} {...props}>
      <CloseIconComponent {...{
      clsPrefix,
      closeIconClass,
      CloseIconComponent,
      disabled
    }} />
      {children}
    </CloseComponent>;
};