import React from 'react';

// Styles
import s from './styles.module.css';

// Components
import { Content as RadixContent, DialogContentProps as RadixContentProps } from '@radix-ui/react-dialog';

// Re export components
export { RadixContent };

// Re export types
export type { RadixContentProps };

// Types
export interface ContentSharedProps {
  ContentComponent?: React.FC<ContentProps> | React.FC<any>;
  contentClass?: string;
}
export interface ContentSpecificProps extends ContentSharedProps {
  clsPrefix?: string;
}
export interface ContentProps extends ContentSharedProps, ContentSpecificProps, RadixContentProps {}
export const Content = ({
  ContentComponent = RadixContent,
  clsPrefix = 'radix-dialog',
  contentClass = '',
  className = contentClass,
  children,
  ...props
}: ContentProps) => {
  return <ContentComponent className={`${s.content} ${clsPrefix}-content ${className}`} {...props}>
      {children}
    </ContentComponent>;
};