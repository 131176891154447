import React, { HtmlHTMLAttributes } from 'react';

// Styles
import s from './styles.module.css';

// Components
import CloseSvg from '../../assets/close.svg';

// Types
export interface CloseIconSharedProps {
  CloseIconComponent?: React.FC<CloseIconProps> | React.FC<any>;
  closeIconClass?: string;
}
export interface CloseIconSpecificProps extends CloseIconSharedProps {
  clsPrefix?: string;
}
export interface CloseIconProps extends CloseIconSpecificProps, HtmlHTMLAttributes<SVGSVGElement> {}
export const CloseIcon = ({
  CloseIconComponent = CloseSvg,
  clsPrefix = 'radix-dialog',
  closeIconClass = '',
  className = closeIconClass,
  ...props
}: CloseIconProps) => {
  return <CloseIconComponent className={`${s.closeIcon} ${clsPrefix}-close-icon ${className}`} {...props} />;
};