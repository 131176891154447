import React from 'react';

// Components
import { Root as RadixRoot, DialogProps as RadixDialogProps } from '@radix-ui/react-dialog';

// Re export components
export { RadixRoot };

// Re export types
export type { RadixDialogProps };

// Types
export interface RootSharedProps {
  RootComponent?: React.FC<RootProps> | React.FC<any>;
}
export interface RootSpecificProps extends RootSharedProps {}
export interface RootProps extends RootSpecificProps, RadixDialogProps {}
export const Root = ({
  RootComponent = RadixRoot,
  children,
  ...props
}: RootProps) => {
  return <RootComponent {...props}>{children}</RootComponent>;
};