import React from 'react';

// Components
import { Trigger as RadixTrigger, DialogTriggerProps as RadixTriggerProps } from '@radix-ui/react-dialog';

// Re export components
export { RadixTrigger };

// Re export types
export type { RadixTriggerProps };

// Types
export interface TriggerSharedProps {
  TriggerComponent?: React.FC<TriggerProps> | React.FC<any>;
  triggerClass?: string;
}
export interface TriggerSpecificProps extends TriggerSharedProps {
  clsPrefix?: string;
}
export interface TriggerProps extends TriggerSpecificProps, RadixTriggerProps {}
export const Trigger = ({
  TriggerComponent = RadixTrigger,
  clsPrefix = 'radix-dialog',
  triggerClass = '',
  className = triggerClass,
  children,
  ...props
}: TriggerProps) => {
  return <TriggerComponent className={`${clsPrefix}-trigger ${className}`} {...props}>
      {children}
    </TriggerComponent>;
};