// Components
import { ConfirmDialog as ParentConfirmDialog, ConfirmDialogProps } from '@ui/radix-confirm-dialog';
import { Dialog as UIDialog } from '../Dialog';

// Hooks
import { useTranslations } from 'next-intl';

// Styles
import { StyledButton } from './styles';
export type { ConfirmDialogProps };
export const ConfirmDialog = ({
  DialogComponent = UIDialog,
  OkButtonComponent = StyledButton,
  CancelButtonComponent = StyledButton,
  cancelButtonProps = {},
  ...props
}: ConfirmDialogProps) => {
  const t = useTranslations('UI.CONFIRM_DIALOG');
  return <ParentConfirmDialog okText={t('OK')} cancelText={t('CANCEL')} {...{
    DialogComponent,
    OkButtonComponent,
    CancelButtonComponent
  }} cancelButtonProps={{
    variant: 'secondary',
    ...cancelButtonProps
  }} {...props}>
      {props.children}
    </ParentConfirmDialog>;
};