import React from 'react';
import styled from 'styled-components';

// Components
import { Button, ButtonProps } from '../Button';

// Styled components
export const StyledButton: React.FC<ButtonProps> = styled(Button)<ButtonProps>`
  width: 100%;
  max-width: 120px;
`;