import React from 'react';

// Components
import { ContentSubWrap as ParentContentSubWrap, ContentSubWrapProps as ParentContentSubWrapProps } from '@ui/radix-dialog';
import { form } from '@ui/base-html-tags';

// Types
export interface ContentSubWrapProps extends ParentContentSubWrapProps {}
export const ContentSubWrap = ({
  ContentSubWrapComponent = form,
  children,
  ...props
}: ContentSubWrapProps) => {
  return <ParentContentSubWrap {...{
    ContentSubWrapComponent
  }} {...props}>
      {children}
    </ParentContentSubWrap>;
};