import { styled, css } from 'styled-components';

// Components
import { Dialog as ParentDialog, Content as ParentContent, DialogProps as ParentDialogProps, ContentProps as ParentContentProps } from '@ui/radix-dialog';

// Types
export interface StyledDialogProps extends ParentDialogProps {}
export interface StyledDialogContentProps extends ParentContentProps {}

// Styled css
export const dialogCss = css<StyledDialogProps>``;
export const dialogContentCss = css<StyledDialogContentProps>`
  [class*='radix-dialog-title'] {
    font-size: 18px;
    margin-bottom: 5px;
  }
  [class*='radix-dialog-description'] {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

// Styled component
export const StyledDialogContent = styled(ParentContent)<StyledDialogContentProps>`
  ${dialogContentCss};
`;
export const StyledDialog = styled(ParentDialog)<StyledDialogProps>`
  ${dialogCss}
`;