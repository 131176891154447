import React, { useState, useEffect } from 'react';

// Utils
import { deepSearchKey } from '@tools/ts-deep-search-key';

// Components
import { Alert as ParentAlert, AlertProps as ParentAlertProps, AlertSpecificProps as ParentAlertSpecificProps } from '@ui/base-alert';

// Types
import type { UseMutationResult } from '@tanstack/react-query';
export interface MutationAlertSpecificProps extends ParentAlertSpecificProps {
  AlertComponent?: React.FC<MutationAlertProps> | React.FC<any>;
  mutation?: UseMutationResult<any, any, any, any>;
  defaultErrorMessage?: string;
  defaultSuccessMessage?: string;
  errorMessage?: string;
  errorMessagePath?: string;
  loadingMessage?: string;
  successMessage?: string;
}
export interface MutationAlertProps extends MutationAlertSpecificProps, ParentAlertProps {}
export const MutationAlert = ({
  AlertComponent = ParentAlert,
  mutation,
  type = 'info',
  show = false,
  defaultErrorMessage = 'Something went wrong, please try again later',
  defaultSuccessMessage,
  loadingMessage = 'Loading...',
  errorMessage,
  errorMessagePath = 'message',
  successMessage,
  onCloseClick,
  ...props
}: MutationAlertProps) => {
  const [stateShow, setStateShow] = useState(show);
  const {
    isPending,
    isSuccess,
    isError,
    error
  } = mutation || {};
  const mutationStatusType = isSuccess ? 'success' : isError ? 'error' : undefined;
  const errorMessageFromData = deepSearchKey(error, errorMessagePath);
  const message = isError ? errorMessage || errorMessageFromData || defaultErrorMessage : isSuccess ? successMessage || defaultSuccessMessage : undefined;
  useEffect(() => {
    if (stateShow !== show) {
      setStateShow(show);
    }
  }, [show]);
  useEffect(() => {
    if (message) {
      setStateShow(true);
    }
  }, [message]);
  const handleCloseClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setStateShow(false);
    onCloseClick?.(e);
  };
  return <AlertComponent type={mutationStatusType || type} message={isPending ? loadingMessage : message} show={stateShow} onCloseClick={handleCloseClick} {...props} />;
};