import React, { useState, useEffect, ReactNode, HtmlHTMLAttributes } from 'react';

// Styles
import s from './styles.module.css';

// Hooks
import { useStickyBoolean } from '@tools/use-sticky-boolean';

// Components
import { div, span } from '@ui/base-html-tags';

// Images
import InfoIcon from '../assets/info.svg';
import ErrorIcon from '../assets/error.svg';
import CloseIcon from '../assets/close.svg';
import WarningIcon from '../assets/warning.svg';
import SuccessIcon from '../assets/success.svg';

// Re export icons
export { InfoIcon, WarningIcon, SuccessIcon, ErrorIcon, CloseIcon };

// Types
export interface AlertSpecificProps {
  WrapComponent?: React.FC<HtmlHTMLAttributes<HTMLDivElement>> | React.FC<any>;
  LeftWrapComponent?: React.FC<HtmlHTMLAttributes<HTMLDivElement>> | React.FC<any>;
  CenterWrapComponent?: React.FC<HtmlHTMLAttributes<HTMLDivElement>> | React.FC<any>;
  RightWrapComponent?: React.FC<HtmlHTMLAttributes<HTMLDivElement>> | React.FC<any>;
  MessageComponent?: React.FC<HtmlHTMLAttributes<HTMLSpanElement>> | React.FC<any>;
  DescriptionComponent?: React.FC<HtmlHTMLAttributes<HTMLSpanElement>> | React.FC<any>;
  InfoIconComponent?: React.FC<HtmlHTMLAttributes<HTMLOrSVGElement>> | React.FC<any>;
  WarningIconComponent?: React.FC<HtmlHTMLAttributes<HTMLOrSVGElement>> | React.FC<any>;
  SuccessIconComponent?: React.FC<HtmlHTMLAttributes<HTMLOrSVGElement>> | React.FC<any>;
  ErrorIconComponent?: React.FC<HtmlHTMLAttributes<HTMLOrSVGElement>> | React.FC<any>;
  CloseButtonComponent?: React.FC<HtmlHTMLAttributes<HTMLButtonElement>> | React.FC<any>;
  CloseIconComponent?: React.FC<HtmlHTMLAttributes<HTMLOrSVGElement>> | React.FC<any>;
  leftWrapClass?: string;
  centerWrapClass?: string;
  rightWrapClass?: string;
  messageClass?: string;
  descriptionClass?: string;
  infoIconClass?: string;
  warningIconClass?: string;
  successIconClass?: string;
  errorIconClass?: string;
  closeButtonClass?: string;
  closeIconClass?: string;
  disabled?: boolean;
  closeDisabled?: boolean;
  closeButtonProps?: HtmlHTMLAttributes<HTMLButtonElement> | any;
  closeIconProps?: HtmlHTMLAttributes<HTMLOrSVGElement> | any;
  show?: boolean;
  iconPosition?: 'left' | 'right';
  clsPrefix?: string;
  showIcon?: boolean;
  showClose?: boolean;
  type?: 'success' | 'info' | 'warning' | 'error';
  message?: ReactNode;
  description?: ReactNode;
  onShowChange?: (show: boolean) => void;
  onCloseClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}
export interface AlertProps extends AlertSpecificProps, HtmlHTMLAttributes<HTMLDivElement> {}
export const Alert = ({
  WrapComponent = div,
  LeftWrapComponent = div,
  CenterWrapComponent = div,
  RightWrapComponent = div,
  MessageComponent = div,
  DescriptionComponent = div,
  InfoIconComponent = InfoIcon,
  WarningIconComponent = WarningIcon,
  SuccessIconComponent = SuccessIcon,
  ErrorIconComponent = ErrorIcon,
  CloseButtonComponent = div,
  CloseIconComponent = CloseIcon,
  leftWrapClass = '',
  centerWrapClass = '',
  rightWrapClass = '',
  messageClass = '',
  descriptionClass = '',
  infoIconClass = '',
  warningIconClass = '',
  successIconClass = '',
  errorIconClass = '',
  closeButtonClass = '',
  closeIconClass = '',
  closeButtonProps = {},
  closeIconProps = {},
  clsPrefix = 'base-alert',
  className = '',
  type = 'warning',
  show = false,
  showIcon = true,
  showClose = true,
  disabled = false,
  closeDisabled = false,
  iconPosition = 'left',
  message = '',
  description = '',
  onShowChange,
  onCloseClick,
  ...props
}: AlertProps) => {
  const [stateShow, _setStateShow] = useState<boolean>(show);
  const stateInitShow = useStickyBoolean(show);
  const setStateShow = (v: boolean) => {
    _setStateShow(v);
    onShowChange?.(v);
  };
  useEffect(() => {
    if (stateShow !== show) {
      _setStateShow(show);
    }
  }, [show]);
  const IconComponent = {
    info: InfoIconComponent,
    warning: WarningIconComponent,
    success: SuccessIconComponent,
    error: ErrorIconComponent
  }[type] || span;
  const iconClass = {
    info: infoIconClass,
    warning: warningIconClass,
    success: successIconClass,
    error: errorIconClass
  }[type] || '';
  const handleCloseClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setStateShow(false);
    onCloseClick?.(e);
  };
  const icon = showIcon ? <IconComponent className={`${clsPrefix}-icon ${clsPrefix}-${type}-icon ${iconClass} `} /> : null;
  const close = <CloseButtonComponent onClick={handleCloseClick} className={`${clsPrefix}-close-button ${closeButtonClass}`} disabled={closeDisabled || disabled} {...closeButtonProps}>
      <CloseIconComponent className={`${clsPrefix}-close-icon ${closeIconClass}`} {...closeIconProps} />
    </CloseButtonComponent>;
  return <WrapComponent data-type={type} data-show={stateShow} data-init-show={stateInitShow} className={`${s?.['wrap']} ${clsPrefix}-wrap ${className}`} {...props}>
      <LeftWrapComponent className={`${s?.['leftWrap']} ${clsPrefix}-left-wrap ${leftWrapClass}`}>
        {iconPosition === 'left' && icon}
        {iconPosition === 'right' && showClose && close}
      </LeftWrapComponent>
      <CenterWrapComponent data-icon-pos={iconPosition} className={`${s?.['centerWrap']} ${clsPrefix}-center-wrap ${centerWrapClass}`}>
        <MessageComponent className={`${s?.['message']} ${clsPrefix}-message ${messageClass}`}>
          {message}
        </MessageComponent>
        <DescriptionComponent className={`${clsPrefix}-description ${descriptionClass}`}>
          {description}
        </DescriptionComponent>
      </CenterWrapComponent>
      <RightWrapComponent className={`${s?.['rightWrap']} ${clsPrefix}-right-wrap ${rightWrapClass}`}>
        {iconPosition === 'right' && icon}
        {iconPosition === 'left' && showClose && close}
      </RightWrapComponent>
    </WrapComponent>;
};
export default Alert;