// Styles
import { StyledDialog, StyledDialogContent, StyledDialogProps } from './styles';

// Types
export interface DialogProps extends StyledDialogProps {}
export const Dialog = ({
  ContentComponent = StyledDialogContent,
  children,
  ...props
}: DialogProps) => {
  return <StyledDialog {...{
    ContentComponent
  }} {...props}>
      {children}
    </StyledDialog>;
};