import React, { useState, useRef, HtmlHTMLAttributes } from 'react';

// Styles
import s from './styles.module.css';

// Components
import { ContentSubWrap } from '../components/ContentSubWrap';
import { Dialog, DialogProps } from '@ui/radix-dialog';
import { Button, ButtonProps } from '@ui/base-button';
import { div } from '@ui/base-html-tags';

// Re export components
export * from '../components/ContentSubWrap';

// Types
export interface ConfirmDialogSpecificProps {
  DialogComponent?: React.FC<DialogProps> | React.FC<any>;
  FooterComponent?: React.FC<HtmlHTMLAttributes<HTMLDivElement>> | React.FC<any>;
  ButtonComponent?: React.FC<ButtonProps> | React.FC<any>;
  OkButtonComponent?: React.FC<ButtonProps> | React.FC<any>;
  CancelButtonComponent?: React.FC<ButtonProps> | React.FC<any>;
  buttonProps?: Partial<ButtonProps>;
  cancelButtonProps?: Partial<ButtonProps>;
  okButtonProps?: Partial<ButtonProps>;
  footerClass?: string;
  okButtonClass?: string;
  cancelButtonClass?: string;
  onOk?: () => void;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  onCancel?: () => void;
  okText?: React.ReactNode;
  cancelText?: React.ReactNode;
  showFooter?: boolean;
  showOk?: boolean;
  showCancel?: boolean;
  disableOk?: boolean;
  disableCancel?: boolean;
  loading?: boolean;
  cancelOnOk?: boolean;
  cancelOnSubmit?: boolean;
  footerPrepend?: React.ReactNode;
  footerAppend?: React.ReactNode;
}
export interface ConfirmDialogProps extends ConfirmDialogSpecificProps, DialogProps {}

// Component
export const ConfirmDialog = ({
  DialogComponent = Dialog,
  ContentSubWrapComponent = ContentSubWrap,
  FooterComponent = div,
  ButtonComponent = Button,
  OkButtonComponent = ButtonComponent,
  CancelButtonComponent = ButtonComponent,
  buttonProps = {},
  cancelButtonProps = {},
  okButtonProps = {},
  contentSubWrapProps = {},
  onOk,
  onCancel,
  okText = 'Ok',
  cancelText = 'Cancel',
  content = '',
  clsPrefix = 'radix-dialog',
  footerClass = '',
  okButtonClass = '',
  cancelButtonClass = '',
  showFooter = true,
  showOk = true,
  showCancel = true,
  disableOk = false,
  disableCancel = false,
  loading = false,
  disabledClose = loading,
  cancelOnOk = false,
  cancelOnSubmit = false,
  onSubmit,
  // @ts-expect-error
  dialogRef = {
    current: {}
  },
  children,
  footerPrepend = '',
  footerAppend = '',
  ...props
}: ConfirmDialogProps) => {
  const dialogRefInternal = useRef(dialogRef?.current);
  const handleCancelClick = async () => {
    try {
      await onCancel?.();
      dialogRefInternal.current?.close?.();
    } catch (e) {
      console.error(e);
    }
  };
  const handleOkClick = async () => {
    try {
      await onOk?.();
      if (cancelOnOk) {
        handleCancelClick();
      }
    } catch (e) {
      console.error(e);
    }
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await onSubmit?.(e);
      if (cancelOnSubmit) {
        handleCancelClick();
      }
    } catch (e) {
      console.error(e);
    }
  };
  const extendedContent = <>
      {content}
      {showFooter && <FooterComponent className={`${s.footer} ${clsPrefix}-footer ${footerClass}`}>
          {footerPrepend}
          {showCancel && <CancelButtonComponent className={`${clsPrefix}-button ${clsPrefix}-cancel-button ${cancelButtonClass}`} onClick={handleCancelClick} disabled={disableCancel || loading || disabledClose} {...buttonProps} {...cancelButtonProps}>
              {cancelText}
            </CancelButtonComponent>}
          {showOk && <OkButtonComponent type="submit" className={`${clsPrefix}-button ${clsPrefix}-ok-button ${cancelButtonClass}`} onClick={handleOkClick} disabled={disableOk} loading={loading} {...buttonProps} {...okButtonProps}>
              {okText}
            </OkButtonComponent>}
          {footerAppend}
        </FooterComponent>}
    </>;
  return <DialogComponent ContentSubWrapComponent={ContentSubWrapComponent} contentSubWrapProps={{
    onSubmit: handleSubmit,
    ...contentSubWrapProps
  }} content={extendedContent} dialogRef={dialogRefInternal} disabledClose={disabledClose} {...props}>
      {children}
    </DialogComponent>;
};