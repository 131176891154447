import React from 'react';

// Styles
import s from './styles.module.css';

// Components
import { Overlay as RadixOverlay, DialogOverlayProps as RadixOverlayProps } from '@radix-ui/react-dialog';

// Re export components
export { RadixOverlay };

// Re export types
export type { RadixOverlayProps };

// Types
export interface OverlaySharedProps {
  OverlayComponent?: React.FC<OverlayProps> | React.FC<any>;
  overlayClass?: string;
}
export interface OverlaySpecificProps extends OverlaySharedProps {
  clsPrefix?: string;
}
export interface OverlayProps extends OverlaySpecificProps, RadixOverlayProps {}
export const Overlay = ({
  OverlayComponent = RadixOverlay,
  clsPrefix = 'radix-dialog',
  overlayClass = '',
  className = overlayClass,
  children,
  ...props
}: OverlayProps) => {
  return <OverlayComponent className={`${s.overlay} ${clsPrefix}-overlay ${className}`} {...props}>
      {children}
    </OverlayComponent>;
};