import React from 'react';

// Styles
import s from './styles.module.css';

// Components
import { Title as RadixTitle, DialogTitleProps as RadixTitleProps } from '@radix-ui/react-dialog';

// Re export components
export { RadixTitle };

// Re export types
export type { RadixTitleProps };

// Types
export interface TitleSharedProps {
  TitleComponent?: React.FC<TitleProps> | React.FC<any>;
  titleClass?: string;
}
export interface TitleSpecificProps extends TitleSharedProps {
  clsPrefix?: string;
}
export interface TitleProps extends TitleSpecificProps, RadixTitleProps {}
export const Title = ({
  TitleComponent = RadixTitle,
  clsPrefix = 'radix-dialog',
  titleClass = '',
  className = titleClass,
  children,
  ...props
}: TitleProps) => {
  return <TitleComponent className={`${s.title} ${clsPrefix}-title ${className}`} {...props}>
      {children}
    </TitleComponent>;
};