import React from 'react';

// Components
import { Portal as RadixPortal, PortalProps as RadixPortalProps } from '@radix-ui/react-portal';

// Re export components
export { RadixPortal };

// Re export types
export type { RadixPortalProps };

// Types
export interface PortalSharedProps {
  PortalComponent?: React.FC<PortalProps> | React.FC<any>;
  portalClass?: string;
}
export interface PortalSpecificProps extends PortalSharedProps {
  clsPrefix?: string;
}
export interface PortalProps extends PortalSpecificProps, RadixPortalProps {}
export const Portal = ({
  PortalComponent = RadixPortal,
  clsPrefix = 'radix-dialog',
  portalClass = '',
  className = portalClass,
  children,
  ...props
}: PortalProps) => {
  return <PortalComponent className={`${clsPrefix}-portal ${className}`} {...props}>
      {children}
    </PortalComponent>;
};