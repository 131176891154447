import React from 'react';

// Styles
import s from './styles.module.css';

// Types
export interface ContentSubWrapSharedProps {
  ContentSubWrapComponent?: React.FC<ContentSubWrapProps> | React.FC<any>;
  contentSubWrapClass?: string;
  contentSubWrapProps?: any;
}
export interface ContentSubWrapSpecificProps extends ContentSubWrapSharedProps {
  clsPrefix?: string;
  className?: string;
  children: React.ReactNode;
}
export interface ContentSubWrapProps extends ContentSubWrapSharedProps, ContentSubWrapSpecificProps {}
const Stub = ({
  children
}: {
  children: React.ReactNode;
}) => <>{children}</>;
export const ContentSubWrap = ({
  ContentSubWrapComponent = Stub,
  clsPrefix = 'radix-dialog',
  contentSubWrapClass = '',
  className = contentSubWrapClass,
  children,
  contentSubWrapProps = {},
  ...props
}: ContentSubWrapProps) => {
  return <ContentSubWrapComponent className={`${s.contentSubWrap} ${clsPrefix}-content-sub-wrap ${className}`} {...props} {...contentSubWrapProps}>
      {children}
    </ContentSubWrapComponent>;
};