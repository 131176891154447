import { styled, css } from 'styled-components';

// Components
import { Alert as ParentAlert, AlertProps as ParentAlertProps } from '@ui/base-alert';

// Types
export interface AlertProps extends ParentAlertProps {}

// Styled css
export const alertCss = css<AlertProps>`
  ${({
  theme,
  type
}) => css`
    ${theme?.['autoSize']?.padding(['12px', '16px'], {
  minSize: '10px'
})}
    gap: 10px;
    border-radius: ${theme?.['borderRadius']?.m};

    .base-alert-description {
      font-size: ${theme?.['fontSize']?.s};
    }

    .base-alert-icon {
      width: 24px;
      height: 24px;
    }

    &[data-type='success'] {
      background-color: ${theme?.['color']?.success100};
      border: 1px solid ${theme?.['color']?.success300};
    }
    &[data-type='info'] {
      background-color: ${theme?.['color']?.primary100};
      border: 1px solid ${theme?.['color']?.primary300};
    }
    &[data-type='warning'] {
      background-color: ${theme?.['color']?.secondary100};
      border: 1px solid ${theme?.['color']?.secondary300};
    }
    &[data-type='error'] {
      background-color: ${theme?.['color']?.error100};
      border: 1px solid ${theme?.['color']?.error300};
    }

    .base-alert-success-icon {
      color: ${theme?.['color']?.success};
    }
    .base-alert-info-icon {
      color: ${theme?.['color']?.primary};
    }
    .base-alert-warning-icon {
      color: ${theme?.['color']?.secondary};
    }
    .base-alert-error-icon {
      color: ${theme?.['color']?.error};
    }

    .base-alert-close-button {
      color: ${theme?.['color']?.grey};
      &[disabled] {
        color: ${theme?.['color']?.grey200};
      }
    }

    ${theme?.['media']?.tablet} {
      border-radius: ${theme?.['borderRadius']?.s};
    }
  `};
`;

// Styled components
export const Alert = styled(ParentAlert)<AlertProps>`
  ${({
  theme
}) => css`
    ${alertCss};
  `};
`;