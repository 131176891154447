// Styles
import { LeftWrap } from './styles';

// Components
import { ConfirmDialog, ConfirmDialogProps } from '../ConfirmDialog';
import { MutationAlert } from '../MutationAlert';

// Types
import type { UseMutationResult } from '@tanstack/react-query';

// Re export types
export interface MutationConfirmDialogProps extends ConfirmDialogProps {
  mutation?: UseMutationResult<any, any, any, any>;
  successMessage?: string;
}
export const MutationConfirmDialog = ({
  children,
  mutation,
  successMessage = 'Successful request!',
  onCancel,
  ...props
}: MutationConfirmDialogProps) => {
  const footerPrepend = mutation && <LeftWrap>
      <MutationAlert mutation={mutation} successMessage={successMessage} />
    </LeftWrap>;
  const handleCancel = () => {
    onCancel?.();
    mutation?.reset?.();
  };
  return <ConfirmDialog footerPrepend={footerPrepend} loading={mutation?.isPending} onCancel={handleCancel} {...props}>
      {children}
    </ConfirmDialog>;
};