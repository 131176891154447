import React from 'react';

// Styles
import s from './styles.module.css';

// Components
import { Description as RadixDescription, DialogDescriptionProps as RadixDescriptionProps } from '@radix-ui/react-dialog';

// Re export components
export { RadixDescription };

// Re export types
export type { RadixDescriptionProps };

// Types
export interface DescriptionSharedProps {
  DescriptionComponent?: React.FC<DescriptionProps> | React.FC<any>;
  descriptionClass?: string;
}
export interface DescriptionSpecificProps extends DescriptionSharedProps {
  clsPrefix?: string;
}
export interface DescriptionProps extends DescriptionSpecificProps, RadixDescriptionProps {}
export const Description = ({
  DescriptionComponent = RadixDescription,
  clsPrefix = 'radix-dialog',
  descriptionClass = '',
  className = descriptionClass,
  children,
  ...props
}: DescriptionProps) => {
  return <DescriptionComponent className={`${s.description} ${clsPrefix}-description ${className}`} {...props}>
      {children}
    </DescriptionComponent>;
};