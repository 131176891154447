import React, { useState, useEffect, ReactNode, MutableRefObject } from 'react';

// Components
import { Root, RootProps, RootSharedProps } from '../components/Root';
import { Trigger, TriggerSharedProps } from '../components/Trigger';
import { Portal, PortalSharedProps } from '../components/Portal';
import { Overlay, OverlaySharedProps } from '../components/Overlay';
import { Content, ContentSharedProps } from '../components/Content';
import { ContentSubWrap, ContentSubWrapSharedProps } from '../components/ContentSubWrap';
import { Title, TitleSharedProps } from '../components/Title';
import { Description, DescriptionSharedProps } from '../components/Description';
import { Close, CloseSharedProps } from '../components/Close';

// Re export components
export * from '../components/Root';
export * from '../components/Trigger';
export * from '../components/Portal';
export * from '../components/Overlay';
export * from '../components/Content';
export * from '../components/Title';
export * from '../components/Description';
export * from '../components/Close';
export * from '../components/CloseIcon';
export * from '../components/ContentSubWrap';

// Types
export interface DialogRef {
  open: () => void;
  close: () => void;
  toggle: () => void;
  forceOpen: () => void;
  forceClose: () => void;
  forceToggle: () => void;
}
export interface DialogSpecificProps extends RootSharedProps, TriggerSharedProps, PortalSharedProps, OverlaySharedProps, ContentSharedProps, ContentSubWrapSharedProps, TitleSharedProps, DescriptionSharedProps, CloseSharedProps {
  content?: ReactNode;
  title?: string | ReactNode;
  description?: string | ReactNode;
  clsPrefix?: string;
  showTitle?: boolean;
  showClose?: boolean;
  showDescription?: boolean;
  asChild?: boolean;
  disabled?: boolean;
  disabledClose?: boolean;
  dialogRef?: MutableRefObject<DialogRef | undefined>;
}
export interface DialogProps extends DialogSpecificProps, RootProps {
  className?: string;
}
export const Dialog = ({
  RootComponent = Root,
  TriggerComponent = Trigger,
  PortalComponent = Portal,
  OverlayComponent = Overlay,
  ContentComponent = Content,
  ContentSubWrapComponent = ContentSubWrap,
  TitleComponent = Title,
  DescriptionComponent = Description,
  CloseComponent = Close,
  contentSubWrapProps = {},
  triggerClass,
  portalClass,
  overlayClass,
  contentClass,
  titleClass,
  descriptionClass,
  closeClass,
  CloseIconComponent,
  clsPrefix = 'radix-dialog',
  className,
  content = '',
  title = '',
  description = '',
  showTitle = true,
  showDescription = true,
  showClose = true,
  asChild = false,
  disabled = false,
  disabledClose = false,
  defaultOpen,
  open,
  onOpenChange,
  children,
  // @ts-expect-error
  dialogRef = {
    current: {}
  },
  ...props
}: DialogProps) => {
  const [stateOpen, _setStateOpen] = useState(defaultOpen ?? open ?? false);
  const isOpenChangeBlocked = (open: boolean) => {
    return disabledClose && !open || disabled && open;
  };
  const setStateOpen = (open: boolean) => {
    if (isOpenChangeBlocked(open)) {
      return;
    }
    _setStateOpen(open);
  };
  useEffect(() => {
    setStateOpen(!!open);
    return () => {
      _setStateOpen(false);
    };
  }, [open]);
  const handleOpenChange = (open: boolean) => {
    if (isOpenChangeBlocked(open)) {
      return;
    }
    setStateOpen(open);
    onOpenChange?.(open);
  };
  Object.assign(dialogRef.current || {}, {
    open: () => handleOpenChange(true),
    close: () => handleOpenChange(false),
    toggle: () => handleOpenChange(!stateOpen),
    forceOpen: () => {
      _setStateOpen(true);
      onOpenChange?.(true);
    },
    forceClose: () => {
      _setStateOpen(false);
      onOpenChange?.(false);
    },
    forceToggle: () => {
      _setStateOpen(prev => !prev);
      onOpenChange?.(!stateOpen);
    }
  });
  return <RootComponent open={stateOpen} onOpenChange={handleOpenChange} {...props}>
      {children && <TriggerComponent {...{
      clsPrefix,
      className,
      triggerClass,
      asChild,
      disabled
    }}>
          {children}
        </TriggerComponent>}
      <PortalComponent {...{
      clsPrefix,
      portalClass
    }}>
        <OverlayComponent {...{
        clsPrefix,
        overlayClass
      }} />
        <ContentComponent {...{
        clsPrefix,
        contentClass
      }}>
          <ContentSubWrapComponent {...{
          clsPrefix
        }} {...contentSubWrapProps}>
            <TitleComponent hidden={!(showTitle && title)} {...{
            clsPrefix,
            titleClass
          }}>
              {title}
            </TitleComponent>

            <DescriptionComponent hidden={!(showDescription && description)} {...{
            clsPrefix,
            descriptionClass
          }}>
              {description}
            </DescriptionComponent>

            {content}

            {showClose && <CloseComponent disabled={disabledClose} {...{
            CloseIconComponent,
            clsPrefix,
            closeClass
          }} />}
          </ContentSubWrapComponent>
        </ContentComponent>
      </PortalComponent>
    </RootComponent>;
};